import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCheckbox,
} from '../../../components';

import css from './SignupForm.module.css';
import { userTypeOptions } from '../../../util/general';
import InternationalPhoneNumberInput from '../../../components/InternationalPhoneNumberInput/InternationalPhoneNumberInput';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        values,
      } = fieldRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const isNPOAdmin = values?.userType === 'npoAdmin';
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            {/* <FieldSelect
              id={formId ? `${formId}.userType` : 'userType'}
              name="userType"
              className={css.selectField}
              label={intl.formatMessage({
                id: 'SignupForm.userTypeLabel',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.requiredSelect',
                })
              )}
            >
              <option disabled value="" hidden>
                {intl.formatMessage({
                  id: 'SignupForm.selectPlaceholder',
                })}
              </option>
              {userTypeOptions.map(i => {
                return (
                  <option key={i.key} value={i.key}>
                    {i.label}
                  </option>
                );
              })}
            </FieldSelect> */}

            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({
                id: 'SignupForm.emailLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
              })}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'SignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.firstNameRequired',
                  })
                )}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.lastNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />
            </div>

            {isNPOAdmin && (
              <>
                {/* <FieldTextInput
                  className={css.formField}
                  type="text"
                  id={formId ? `${formId}.personalPostalAddress` : 'personalPostalAddress'}
                  name="personalPostalAddress"
                  label={intl.formatMessage({
                    id: 'SignupForm.personalPostalAddressLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.textPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.textRequired',
                    })
                  )}
                /> */}
                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id={formId ? `${formId}.associationName` : 'associationName'}
                  name="associationName"
                  label={`Nom de l'association`}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.textPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.textRequired',
                    })
                  )}
                />

                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id={formId ? `${formId}.address` : 'address'}
                  name="address"
                  label={'Adresse'}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.textPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.textRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id={formId ? `${formId}.secondAddress` : 'secondAddress'}
                  name="secondAddress"
                  label={`Complément d'adresse`}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.textPlaceholder',
                  })}
                  // validate={validators.required(
                  //   intl.formatMessage({
                  //     id: 'SignupForm.textRequired',
                  //   })
                  // )}
                />
                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id={formId ? `${formId}.zip` : 'zip'}
                  name="zip"
                  label={'Code postal'}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.textPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.textRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id={formId ? `${formId}.city` : 'city'}
                  name="city"
                  label={'Ville'}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.textPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.textRequired',
                    })
                  )}
                />

                <FieldTextInput
                  className={css.formField}
                  type="text"
                  id={formId ? `${formId}.identificationNumber` : 'identificationNumber'}
                  name="identificationNumber"
                  label={intl.formatMessage({
                    id: 'SignupForm.identificationNumberLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.textPlaceholder',
                  })}
                  // validate={validators.required(
                  //   intl.formatMessage({
                  //     id: 'SignupForm.textRequired',
                  //   })
                  // )}
                />

                {/* <InternationalPhoneNumberInput
                  label={intl.formatMessage({
                    id: 'SignupForm.companyPhoneNumber',
                  })}
                  name={'phoneNumber'}
                  id={'phoneNumber'}
                /> */}
              </>
            )}
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.passwordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
          </div>

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <FieldCheckbox
              id="newsletterSubscription"
              name="newsletterSubscription"
              label={<FormattedMessage id="SignupForm.enrollToNewsletter" />}
              value="true"
              className={css.checkboxField}
            />
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
