import React, { memo } from 'react';
import css from './InternationalPhoneNumberInput.module.css';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';
import { useEffect } from 'react';

const PhoneField = memo(({ input: { onChange, value }, label, ...rest }) => {
  return (
    <div className={css.wrapper}>
      <p className={css.label}>{label}</p>
      <PhoneInput placeholder="Enter phone number" value={value} onChange={p => onChange(p)} />
    </div>
  );
});

const InternationalPhoneNumberInput = props => {
  const { name, id, label } = props;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('react-phone-number-input/style.css');
    }
  }, []);

  return <Field name={name} label={label} component={PhoneField} />;
};

export default InternationalPhoneNumberInput;
